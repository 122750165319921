<template>
  <div class="w-full flex flex-col gap-3">
    <div v-if="!showError">
    <p class="text-base font-semibold">Leave</p>

    <div style="height: 100%" class="mt-28 pt-10" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>

    <div class="flex flex-col w-full my-3" v-else>
      <div class="w-full gap-5 mb-6 flex">
        <Card class="w-full py-3 px-4">
          <div class="flex justify-between p-3 gap-5 bg-ghostWhite">
            <div class="flex w-full gap-3">
              <div
                class="p-2 h-10 rounded-md flex"
                style="background-color: rgba(33, 118, 255, 0.1)"
              >
                <Icon
                  class-name="text-blueCrayola"
                  icon-name="cancelNotification"
                  size="xsm"
                />
              </div>
              <div class="flex flex-col">
                <span class="text-base font-semibold text-blueCrayola"
                  >Total Requested</span
                >
                <span class="text-lg font-extrabold">{{
                  leaveCount
                }}</span>
              </div>
            </div>
            <div class="border-2 border-dashed bg-white w-0" />
            <div class="flex w-full gap-3">
              <div
                class="p-2 h-10 rounded-md flex"
                style="background-color: rgba(233, 147, 35, 0.1)"
              >
                <Icon
                  class-name="text-carrotOrange"
                  icon-name="cancelNotification"
                  size="xsm"
                />
              </div>
              <div class="flex flex-col">
                <span class="text-base font-semibold text-carrotOrange"
                  >Total Approved</span
                >
                <span class="text-lg font-extrabold">{{
                  summaryData.approved
                }}</span>
              </div>
            </div>
            <div class="border-2 border-dashed bg-white w-0" />
            <div class="flex w-full gap-3">
              <div
                class="p-2 h-10 rounded-md flex"
                style="background-color: rgba(19, 181, 106, 0.1)"
              >
                <Icon
                  class-name="text-mediumSeaGreen"
                  icon-name="cancelNotification"
                  size="xsm"
                />
              </div>
              <div class="flex flex-col">
                <span class="text-base font-semibold text-mediumSeaGreen"
                  >Total Taken</span
                >
                <span class="text-lg font-extrabold">{{
                  summaryData.taken
                }}</span>
              </div>
            </div>
          </div>
        </Card>
      </div>

      <div class="flex flex-col w-full my-3" v-if="leaveData.length > 0">
        <sTable
          :headers="leaveHeaders"
          :items="leaveData"
          style="width: 100%"
          aria-label="employee table"
          class="w-full"
          has-number
        >
          <template v-slot:item="{ item }">
            <div v-if="item.type" class="py-3">
              {{ item.data.type ? item.data.type : "---" }}
            </div>
            <div v-else-if="item.requestReason" class="py-3">
              {{ item.data.requestReason ? item.data.requestReason : "---" }}
            </div>
            <div v-else-if="item.approvalStatus" class="py-3">
              <div
                style="width: 100px"
                :class="{
                  pending: item.data.approvalStatus === 'pending',
                  approved: item.data.approvalStatus === 'approved',
                  disapproved: item.data.approvalStatus === 'disapproved',
                }"
              >
                {{
                  item.data.approvalStatus
                    ? Capitalize(item.data.approvalStatus)
                    : "---"
                }}
              </div>
            </div>
          </template>
        </sTable>
      </div>

      <div class="flex flex-col gap-1" v-else>
        <div class="flex w-full justify-center">
          <Icon
            icon-name="dashboard-error"
            class="-mt-1 iconSize"
            style="width: 250px; height: 250px"
          />
        </div>
        <p class="text-xl text-center font-bold">No Data Available</p>
        <p class="text-xs text-center text-romanSilver">
          There is no data to show currently.
        </p>
      </div>
    </div>
    </div>
    <div v-else>
      <ErrorPage />
    </div>
  </div>
</template>

<script>
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@/components/Card";
import ErrorPage from "@/modules/Admin/error403";

export default {
  name: "LeaveHistory",
  components: { Card, STable, ErrorPage },
  data() {
    return {
      summaryData: {},
      loading: true,
      leaveCount: null,
      leaveHeaders: [
        { title: "Leave Type", value: "type" },
        { title: "Description", value: "requestReason" },
        { title: "Start Date", value: "requestStartDate" },
        { title: "End Date", value: "requestEndDate" },
        { title: "Duration (Days)", value: "daysRequested" },
        { title: "Status", value: "approvalStatus" },
      ],
      leaveData: [],
      showError: false,
    };
  },
  props: {
    userDetails: {
      type: Object,
      default: () => {},
    },
  },

 async mounted() {
   try {
     await this.$handlePrivilege("employeeDirectory", "viewLeaveHistoryED");
     this.$_employeeLeaveInsight(this.userDetails.userId).then((response) => {
       this.summaryData = response.data.counts;
       this.leaveCount = response.data.leaveCount;
       this.leaveData = response.data.requests.map((request) => ({
         ...request,
         type: request.category.name,
       }));
       this.loading = false;
     });
   } catch (error) {
      this.showError = true;
    }
  },
};
</script>