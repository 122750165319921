<template>
  <div class="w-full flex flex-col gap-3">
    <div v-if="!showError">
      <p class="text-base font-semibold">Timeline</p>
      <p class="text-sm text-romanSilver">
        View {{ userDetails.fname }} {{ userDetails.lname }}’s timeline
      </p>

      <div style="height: 100%" class="mt-28 pt-10" v-if="loading">
        <loader size="xxs" :loader-image="false" />
      </div>

      <div class="flex flex-col my-3 gap-12" v-else>
        <div v-if="timelineData.length > 0" class="gap-8 flex flex-col">
          <div
            class="w-full flex justify-between items-center py-4 px-6 border rounded-md"
            v-for="(timeline, index) in timelineData"
            :key="index"
          >
            <div class="flex flex-col gap-2">
              <p class="text-base font-semibold capitalize">
                {{ $convertCamelCaseToTitle(timeline.requestSlug) }}
              </p>
              <p class="text-sm text-romanSilver">{{ timeline.description }}</p>
            </div>

            <div class="flex gap-1">
              <p class="text-sm text-romanSilver">
                {{ $DATEFORMAT(new Date(timeline.createdAt), "MMMM dd, yyyy") }}
              </p>
              <p class="text-sm text-romanSilver">
                {{ $DATEFORMAT(new Date(timeline.createdAt), "hh:mm a") }}
              </p>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-1" v-else>
          <div class="flex w-full justify-center">
            <Icon
              icon-name="dashboard-error"
              class="-mt-1 iconSize"
              style="width: 250px; height: 250px"
            />
          </div>
          <p class="text-xl text-center font-bold">No Data Available</p>
          <p class="text-xs text-center text-romanSilver">
            There is no data to show currently.
          </p>
        </div>
      </div>
    </div>
    <div v-else>
      <ErrorPage />
    </div>
  </div>
</template>

<script>
import ErrorPage from "@/modules/Admin/error403";

export default {
  name: "Timeline",
  components: { ErrorPage },
  data() {
    return {
      timelineData: [],
      loading: true,
      showError: false,
    };
  },
  props: {
    userDetails: {
      type: Object,
      default: () => {},
    },
  },
  async mounted() {
    try {
      await this.$handlePrivilege("employeeDirectory", "viewTimelineED");
      this.$_getEmployeeTimelineInsights(this.userDetails.userId)
        .then((response) => {
          this.loading = false;
          this.timelineData = response.data.data.timeline;
        })
        .catch(() => {
          this.loading = false;
        });
    } catch (error) {
      this.showError = true;
    }
  },
};
</script>
