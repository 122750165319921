<template>
  <div>
    <p class=" font-bold text-lg mb-7">
      Account Information
    </p>
    <ValidationObserver rules="required" v-slot="{ handleSubmit }">
      <div class=" w-full">
        <p class=" font-semibold mb-4 inline-block">
          Bank Information
        </p>
        <span
          v-if="!cannotEdit"
          class="float-right cursor-pointer text-flame text-base font-semibold"
        >
          <span v-if="!bankEdit" @click="handleClick('bank')">
            <icon
              icon-name="icon-edit"
              size="xs"
              class="cursor-pointer inline-block"
            />
            <span class="pl-2">Edit</span>
          </span>
          <span @click="handleSubmit(saveBankInfo)" v-else>
            <icon
              icon-name="save"
              size="xs"
              class="cursor-pointer inline-block"
            />
            <span class="pl-2">Save</span>
          </span>
        </span>
      </div>
      <div
        class="grid grid-cols-12 gap-6 text-darkPurple"
        style="margin-top:1.125rem"
      >
        <div class="col-span-3">
          <c-select
            placeholder="--Enter--"
            :options="banks"
            variant="w-full"
            label="Bank"
            @input="accountInformation.accountNo = null"
            v-model="accountInformation.bank"
            :disabled="!bankEdit"
          />
        </div>
        <div class="col-span-3">
          <c-text
            placeholder="--Enter--"
            label="Account Number"
            v-model="accountInformation.accountNo"
            type="number"
            :rules="numberRule"
            :disabled="!bankEdit || disableNumber || !accountInformation.bank"
          />
          <small v-if="loadingAccount" class="text-xs">Loading...</small>
          <small class=" text-flame font-bold " v-if="error">{{
            `⚠️ ${error}`
          }}</small>
          <small
            class=" text-mediumSeaGreen font-bold"
            v-if="accountInformation.accountName"
            >{{ ` ${accountInformation.accountName} ✅  ` }}</small
          >
        </div>
        <div class="col-span-3" v-feature-request>
          <c-text
            placeholder="--Enter--"
            variant="w-full h-10"
            label="BVN"
            v-model="accountInformation.bvn"
            :disabled="!bankEdit"
          />
        </div>
        <div class="col-span-12">
          <divider class="my-4 w-full" />
        </div>
        <div class="col-span-12 w-full">
          <p class=" font-semibold mb-4 inline-block">
            Tax Information
          </p>
          <span
            v-if="!cannotEdit"
            class="float-right cursor-pointer text-flame text-base font-semibold"
          >
            <span @click="handleClick('tax')" v-if="!taxEdit">
              <icon
                icon-name="icon-edit"
                size="xs"
                class="cursor-pointer inline-block"
              />
              <span class="pl-2">Edit</span>
            </span>
            <span @click="saveAccountInfo('tax')" v-else>
              <icon
                icon-name="save"
                size="xs"
                class="cursor-pointer inline-block"
              />
              <span class="pl-2">Save</span>
            </span>
          </span>
        </div>
        <div class="col-span-3">
          <c-select
            placeholder="--Enter--"
            :options="states"
            variant="w-full"
            label="SIRS"
            v-model="accountInformation.sirs"
            :disabled="!taxEdit"
          />
        </div>
        <div class="col-span-3">
          <c-text
            placeholder="--Enter--"
            label="Employee Tax ID"
            v-model="accountInformation.employeeTaxId"
            :disabled="!taxEdit"
          />
        </div>
        <div class="col-span-12">
          <divider class="my-4 w-full" />
        </div>
      </div>
    </ValidationObserver>
    <div class="col-span-12 w-full">
      <p class=" font-semibold mb-4 inline-block">
        Pension Information
      </p>
      <span
        v-if="!cannotEdit"
        class="float-right cursor-pointer text-flame text-base font-semibold"
      >
        <span @click="handleClick('pension')" v-if="!pensionEdit">
          <icon
            icon-name="icon-edit"
            size="xs"
            class="cursor-pointer inline-block"
          />
          <span class="pl-2">Edit</span>
        </span>
        <span @click="saveAccountInfo('pension')" v-else>
          <icon
            icon-name="save"
            size="xs"
            class="cursor-pointer inline-block"
          />
          <span class="pl-2">Save</span>
        </span>
      </span>
    </div>
    <div
      class="grid grid-cols-12 gap-6 text-darkPurple"
      style="margin-top:1.125rem"
    >
      <div class="col-span-3">
        <c-select
          placeholder="--Enter--"
          :options="pencom"
          variant="w-full"
          label="PFA"
          v-model="accountInformation.pfaId"
          :disabled="!pensionEdit"
        />
      </div>
      <div class="col-span-3">
        <c-text
          placeholder="--Enter--"
          label="RSA PIN"
          v-model="accountInformation.rsaId"
          :disabled="!pensionEdit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { clone } from "lodash";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import { mapState } from "vuex";
import Divider from "@/components/divider";

export default {
  components: { CText, CSelect, Divider, ValidationObserver },
  name: "AccountInfo",
  props: {
    cannotEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      bankEdit: false,
      taxEdit: false,
      pensionEdit: false,
      disableNumber: false,
      loadingAccount: false,
      error: "",
      states: [],
      numberRule: [
        "required",
        {
          name: "number",
          rule: v => this.validateNumber(v)
        }
      ],
      accountInformation: {
        accountNo: null,
        bank: null,
        accountName: null,
        bvn: null,
        irs: null,
        rsaId: null,
        pfaId: null,
        employeeTaxId: null
      }
    };
  },
  computed: {
    ...mapState({
      pencom: state =>
        state.pension.PFAOrgList.map(pension => ({
          id: pension.pfaId,
          name: pension.pfaName
        })),
      employeeDetails: state => state.employee.allEmployeeDetails,
      banks: state =>
        state.Banks.map(bank => ({
          ...bank,
          id: bank.code
        }))
    })
  },

  methods: {
    validateNumber(value) {
      this.error = "";
      this.accountInformation.accountName = null;
      if (value.toString().length === 10) {
        this.disableNumber = true;
        if (!this.accountInformation.accountName) {
          this.loadingAccount = true;
        }
        this.handleAccountChange(value);
        return true;
      }
      return "Account number must be 10 numbers";
    },
    handleAccountChange(value) {
      this.$_checkAccountNo({
        bankCode: this.accountInformation.bank,
        acctNumber: value
      })
        .then(result => {
          if (result.status === 200) {
            this.error = "";
            this.loadingAccount = false;
            this.disableNumber = false;
            this.accountInformation.accountName = result.data.data.account_name;
          }
        })
        .catch(() => {
          this.loadingAccount = false;
          this.disableNumber = false;
          this.accountInformation.accountName = null;
          this.error = "Incorrect Account number";
        });
    },
    async handleClick(type) {
      try {
        await this.$handlePrivilege("employeeDirectory", "editEmployeePayInfo");
        if (type === "bank") {
          this.bankEdit = !this.bankEdit;
        }
        if (type === "tax") {
          this.taxEdit = !this.taxEdit;
        }
        if (type === "pension") {
          this.pensionEdit = !this.pensionEdit;
        }
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000
        });
      }
    },
    saveAccountInfo(section) {
      if (section === "bank") {
        this.saveBankInfo();
      }

      if (section === "tax") {
        this.saveTaxInfo();
      }

      if (section === "pension") {
        this.savePensionInfo();
      }
    },
    saveBankInfo() {
      this.$_saveEmployeeBankInfo({
        userId: this.$route.params.id,
        data: {
          bank: this.accountInformation.bank,
          accountNo: this.accountInformation.accountNo,
          bvn: this.accountInformation.bvn
        }
      }).then(() => {
        this.$store.dispatch(
          "employee/getOneEmployeeAll",
          this.$route.params.id
        );
        this.$toasted.success("Account details saved Successfully", {
          duration: 3000
        });
        this.bankEdit = !this.bankEdit;
      });
    },
    saveTaxInfo() {
      this.$_saveEmployeeTaxInfo({
        userId: this.$route.params.id,
        data: {
          sirs: this.accountInformation.sirs,
          employeeTaxId: this.accountInformation.employeeTaxId
        }
      }).then(() => {
        this.$store.dispatch(
          "employee/getOneEmployeeAll",
          this.$route.params.id
        );
        this.$toasted.success("Tax information saved Successfully", {
          duration: 3000
        });
        this.taxEdit = !this.taxEdit;
      });
    },
    savePensionInfo() {
      this.$_saveEmployeePensionInfo({
        userId: this.$route.params.id,
        data: {
          pfaId: this.accountInformation.pfaId,
          rsaId: this.accountInformation.rsaId
        }
      }).then(() => {
        this.$store.dispatch(
          "employee/getOneEmployeeAll",
          this.$route.params.id
        );
        this.$toasted.success("Pension information saved Successfully", {
          duration: 3000
        });
        this.pensionEdit = !this.pensionEdit;
      });
    }
  },
  async mounted() {
    await this.$store.dispatch("getBanks");

    this.$_getTaxAuthority().then(item => {
      this.states = item.data.taxAuthorities;
    });
    await this.$store.dispatch("pension/getPFAOrgList");
    this.accountInformation = await clone(this.employeeDetails);
  }
};
</script>

<style></style>
