<template>
  <div class="w-full flex flex-col my-3">
    <div class="w-full h-full flex flex-col gap-12">
      <Card class="w-full py-3 px-4 rounded-md flex gap-5">
        <div class="w-full flex justify-between">
          <div class="flex w-full flex-grow">
            <div class="flex w-full gap-3">
              <img
                :src="userDetails.photo"
                class="w-32 h-44"
                v-if="userDetails.photo"
              />
              <div
                class="w-32 h-44 flex flex-col text-lg justify-center text-center font-semibold border rounded-md"
                v-else
              >
                {{ $getInitials(`${userDetails.fname} ${userDetails.lname}`) }}
              </div>

              <div class="w-full flex flex-col gap-1">
                <div class="w-full flex gap-8 mb-2">
                  <div class="flex items-start">
                    <p class="text-lg font-bold">
                      {{ userDetails.fname }}
                      {{ userDetails.lname }}
                    </p>
                  </div>
                </div>

                <div
                  class="w-full flex"
                  v-for="(option, index) in employeeOptions"
                  :key="index"
                >
                  <div class="w-1/5 flex items-start">
                    <p class="text-sm font-semibold text-romanSilver">
                      {{ option.title }}:
                    </p>
                  </div>
                  <div class="w-4/5 flex items-start">
                    <p class="text-sm font-semibold">
                      {{ option.value }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col gap-3">
            <div class="flex gap-3">
              <Button
                class="font-semibold bg-dynamicBackBtn text-white"
                style="border: 1px solid #f15a29"
                width="7rem"
              >
                Terminate
              </Button>

              <Button
                class="font-semibold text-dynamicBackBtn border border-solid border-dynamicBackBtn"
                width="7rem"
              >
                Edit
              </Button>
            </div>
          </div>
        </div>
      </Card>

      <div class="w-full flex justify-center">
        <div class="w-4/5 border-2" />
      </div>

      <Card class="w-full py-3 px-4 rounded-md flex gap-5">
        <Card
          class="flex flex-grow py-3 px-4 w-1/2 rounded-md flex flex-col gap-5"
        >
          <div class="flex flex-col w-full gap-5">
            <p class="text-base font-bold">Monthly Earning</p>
            <CSelect
              placeholder=" --Select-- "
              :options="optionMonth"
              variant="w-1/3 h-10"
              class="w-1/2"
              label="Pay Date"
              v-model="payDate"
            />
            <div class="flex justify-between items-center">
              <div class="flex gap-2">
                <Icon size="s" icon-name="pay-folders" />
                <div class="flex flex-col justify-center">
                  <p class="text-xs text-romanSilver">Gross Pay Amount</p>
                  <p class="text-lg font-bold">
                    {{ grossPay ? grossPay : "---" }}
                  </p>
                </div>
              </div>
              <div class="flex gap-2">
                <div class="flex flex-col justify-center">
                  <p class="text-xs text-romanSilver">Pay Period</p>
                  <p class="text-sm font-semibold">
                    {{ payPeriod ? payPeriod : "---" }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full flex justify-center">
            <div class="w-full border-dashed border-2" />
          </div>
          <div class="flex w-full flex-col gap-3">
            <loader v-if="loadingMonthly" size="xxs" :loader-image="false" />
            <div class="flex flex-col" v-else>
              <p class="text-base font-bold mb-5">Breakdown</p>
              <div
                class="flex w-full gap-3"
                v-if="Object.keys(monthlyChartData).length > 0"
              >
                <DoughnutChart
                  :options="monthlyChartOptions"
                  :data="monthlyChartData"
                  :chart-data="monthlyChartData"
                  :styles="{
                    height: '180px',
                    width: '180px',
                    position: 'relative',
                  }"
                />

                <div class="flex flex-col justify-center gap-2 ml-4">
                  <div
                    class="flex gap-2"
                    v-for="(legend, index) in monthlyLegend"
                    :key="index"
                  >
                    <div
                      class="rounded-full mt-1"
                      :style="`width: 12px; height: 12px; background-color: ${legend.color}`"
                    />
                    <p class="text-sm">
                      {{ legend.name }}
                    </p>
                    <p class="text-sm font-semibold">
                      {{ convertToCurrency(legend.value) }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="flex flex-col gap-1" v-else>
                <div class="flex w-full justify-center">
                  <Icon
                    icon-name="dashboard-error"
                    class="-mt-1 iconSize"
                    style="width: 180px; height: 180px"
                  />
                </div>
                <p class="text-xl text-center font-bold">No Data Available</p>
                <p class="text-xs text-center text-romanSilver">
                  There is no data to show currently.
                </p>
              </div>
            </div>
          </div>
        </Card>
        <Card
          class="flex py-3 flex-grow px-4 rounded-md w-1/2 flex flex-col gap-5"
        >
          <div class="flex flex-col w-full gap-5">
            <p class="text-base font-bold">Annual Earning</p>
            <CSelect
              placeholder=" --Select-- "
              :options="optionYears"
              variant="w-1/3 h-10"
              class="w-1/2"
              label="Year"
              v-model="selectedYear"
            />
            <div class="flex justify-between items-center">
              <div class="flex gap-2">
                <Icon size="s" icon-name="pay-folders" />
                <div class="flex flex-col justify-center">
                  <p class="text-xs text-romanSilver">Total Gross Earning</p>
                  <p class="text-lg font-bold">
                    {{ totalGrossPay ? totalGrossPay : "---" }}
                  </p>
                </div>
              </div>
              <div class="flex gap-2">
                <div class="flex flex-col justify-center">
                  <p class="text-xs text-romanSilver">Pay Period</p>
                  <p class="text-sm font-semibold">
                    {{ annualPayPeriod ? annualPayPeriod : "---" }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="w-full flex justify-center">
            <div class="w-full border-dashed border-2" />
          </div>
          <div class="flex w-full flex-col gap-3">
            <loader v-if="loadingAnnual" size="xxs" :loader-image="false" />
            <div class="flex flex-col" v-else>
              <p class="text-base font-bold mb-5">Breakdown</p>
              <div
                class="flex w-full gap-3"
                v-if="Object.keys(annualChartData).length > 0"
              >
                <DoughnutChart
                  :options="annualChartOptions"
                  :data="annualChartData"
                  :chart-data="annualChartData"
                  :styles="{
                    height: '180px',
                    width: '180px',
                    position: 'relative',
                  }"
                />

                <div class="flex flex-col justify-center gap-2 ml-4">
                  <div
                    class="flex gap-2"
                    v-for="(legend, index) in annualLegend"
                    :key="index"
                  >
                    <div
                      class="rounded-full mt-1"
                      :style="`width: 12px; height: 12px; background-color: ${legend.color}`"
                    />
                    <p class="text-sm">
                      {{ legend.name }}
                    </p>
                    <p class="text-sm font-semibold">{{ legend.value }}%</p>
                  </div>
                </div>
              </div>
              <div class="flex flex-col gap-2" v-else>
                <div class="flex w-full justify-center">
                  <Icon
                    icon-name="dashboard-error"
                    class="-mt-1 iconSize"
                    style="width: 180px; height: 180px"
                  />
                </div>
                <p class="text-xl text-center font-bold">No Data Available</p>
                <p class="text-xs text-center text-romanSilver">
                  There is no data to show currently.
                </p>
              </div>
            </div>
          </div>
        </Card>
      </Card>

      <div class="w-full flex flex-col gap-5">
        <p class="text-base font-semibold text-darkPurple">Payslips</p>

        <div class="w-full flex flex-col">
          <p class="font-semibold text-base my-2">Filter By</p>
          <div class="flex justify-between items-center">
            <div class="flex flex-grow">
              <radio-button
                :options="optionBox"
                col-span="col-span-6"
                class="mt-4 text-darkPurple"
                row-gap="gap-x-8"
                space-between="mr-2"
                v-model="filterCheck"
              />
            </div>
            <Card class="flex p-3 gap-3 justify-end">
              <date-range-picker
                :range="paySlipFilter === 'range'"
                :editable="true"
                type="month"
                v-model="selectedDate"
                placeholder="Select Date"
                @input="getDateRange($event)"
              />

              <div class="flex w-auto">
                <Button
                  width="5rem"
                  class=" bg-dynamicBackBtn text-white -mt-2"
                  @click="filterPaySlip()"
                >
                  Apply
                </Button>
              </div>
            </Card>
          </div>
        </div>
      </div>

      <div style="height: 100%" class="mt-2" v-if="loadingTable">
        <loader size="xxs" :loader-image="false" />
      </div>

      <div v-else>
        <sTable
          :headers="paySlipHeader"
          :items="paySlipData"
          v-if="paySlipData.length > 0"
          class="w-full mt-4 mb-6"
          :has-number="false"
          aria-label="requestTable"
          :loading="false"
        >
          <template v-slot:item="{ item }">
            <div v-if="item.year" class="underline pt-2 cursor-pointer">
              <span
                class="w-auto"
                @click="
                  $router.push({
                    name: 'viewPaySlip',
                    params: { id: item.data.id },
                  })
                "
              >
                {{
                  $DATEFORMAT(
                    new Date(
                      item.data.year + "-" + "0" + item.data.month + "-" + 1
                    ),
                    "LLLL yyyy"
                  )
                }}
                Payslip
              </span>
            </div>
            <div v-else-if="item.payrun">
              {{
                item.data.payrun.payFrequency
                  ? handlePayFrequency(item.data.payrun.payFrequency)
                  : "-"
              }}
            </div>
            <div v-else-if="item.documentId">
              {{
                `${$DATEFORMAT(
                  new Date(item.data.payrun.month + "-" + 1),
                  "LLLL"
                )} ${item.data.payrun.payStartDay} - ${
                  item.data.payrun.payEndDay
                }`
              }}
            </div>
            <div class="flex w-4/6 justify-between" v-else-if="item.id">
              <div
                @click="viewPaySlip(item.data.id)"
                class="flex text-blueCrayola py-1 more h-8 cursor-pointer"
              >
                <icon
                  icon-name="icon-eye"
                  class-name="text-blueCrayola"
                  size="xs"
                />
                <p class="underline text-sm ml-3">View</p>
              </div>
            </div>
          </template>
        </sTable>

        <div v-if="paySlipData.length === 0" class="mt-4">
          <div class="flex font-bold text-desire text-base justify-center">
            You will find all payslips for this employee here, from the time you
            pay the employee using Cloudenly.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateRangePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import RadioButton from "@/components/RadioButton";
import DoughnutChart from "@/components/donutChart";

export default {
  name: "PayAnalytics",
  components: {
    Card: () => import("@/components/Card"),
    Button: () => import("@/components/Button"),
    STable,
    RadioButton,
    DateRangePicker,
    DoughnutChart,
    CSelect,
  },
  props: {
    userDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loadingTable: true,
      loadingMonthly: true,
      loadingAnnual: true,
      payDate: null,
      selectedYear: null,
      selectedDate: null,
      filterCheck: "range",
      paySlipHeader: [
        { title: "PaySlip", value: "year", width: 25 },
        { title: "Pay Frequency", value: "payrun", width: 25 },
        { title: "Pay Period", value: "documentId", width: 25 },
        { title: "Actions", value: "id", width: 22 },
      ],
      paySlipData: [],
      optionYears: [],
      optionBox: [
        { name: "Specific Month", radioName: "month", value: "specific" },
        { name: "Period", radioName: "month", value: "range" },
      ],
      paySlipFilter: "range",
      optionMonth: [],
      start: {},
      end: {},
      grossPay: null,
      totalGrossPay: null,
      payPeriod: "",
      annualPayPeriod: "",
      monthlyChartData: {},
      annualChartData: {},
      monthlyLegend: [],
      annualLegend: [],
      monthlyChartOptions: {
        legend: {
          display: false,
          position: "right",
          labels: {
            usePointStyle: true,
            fontSize: 14,
            fontColor: "#333333",
          },
        },
        responsive: true,
        cutoutPercentage: 60,
        maintainAspectRatio: false,
      },
      annualChartOptions: {
        legend: {
          display: false,
          position: "right",
          labels: {
            usePointStyle: true,
            fontSize: 14,
            fontColor: "#333333",
          },
        },
        responsive: true,
        cutoutPercentage: 60,
        maintainAspectRatio: false,
      },
      employeeOptions: [
        {
          title: "Function",
          value: this.userDetails.orgFunction?.name,
        },
        {
          title: "Designation",
          value: this.userDetails.userDesignation?.name,
        },
        {
          title: "Job Level",
          value: this.userDetails.userLevel?.name,
        },
        {
          title: "Office Location",
          value: this.userDetails.employeeOffice?.name,
        },
        {
          title: "Manager",
          value: `${this.userDetails.userReportingTo?.fname} ${this.userDetails.userReportingTo?.lname}`,
        },
        {
          title: "Length of Service",
          value: `${this.getLengthService()}`,
        },
      ],
    };
  },
  watch: {
    filterCheck(data) {
      this.paySlipFilter = data;
    },
    payDate(data) {
      const month = data.split("-")[0];
      const year = data.split("-")[1];
      this.monthlyLegend = [];
      this.monthlyChartData = {};
      this.grossPay = null;
      this.payPeriod = null;
      this.getMonthly(month, year);
    },
    selectedYear(data) {
      this.annualLegend = [];
      this.annualChartData = {};
      this.totalGrossPay = null;
      this.annualPayPeriod = null;
      this.getYearly(data);
    },
  },
  methods: {
    getMonthly(month, year) {
      let i;
      this.loadingMonthly = true;
      this.$_getMonthlyEarnings(month, year, this.userDetails.userId)
        .then((response) => {
          this.grossPay = this.convertToCurrency(
            response.data.payinfo.grossPay
          );
          this.payPeriod = `01/${month}/${year} - ${
            response.data.payinfo.payDay.split("-")[2]
          }/${response.data.payinfo.payDay.split("-")[1]}/${
            response.data.payinfo.payDay.split("-")[0]
          }`;
          const netPay = Math.round(response.data.payinfo.netPay);
          const deductions = Math.round(response.data.payinfo.deductions);
          const pension = Math.round(response.data.payinfo.totalPensionAmount);
          const paye = Math.round(response.data.payinfo.monthlyPAYE);
          this.monthlyChartData = {
            labels: ["Net Pay Amount", "Deductions", "Pension", "PAYE"],
            datasets: [
              {
                data: [netPay, deductions, pension, paye],
                backgroundColor: ["#F15A29", "#490166", "#2176FF", "#13B56A"],
              },
            ],
          };
          for (i = 0; i < this.monthlyChartData.labels.length; i++) {
            const objData = {
              color: this.monthlyChartData.datasets[0].backgroundColor[i],
              name: this.monthlyChartData.labels[i],
              value: `${this.monthlyChartData.datasets[0].data[i]}`,
            };
            this.monthlyLegend.push(objData);
          }
          this.loadingMonthly = false;
        })
        .catch(() => {
          this.loadingMonthly = false;
        });
    },

    getYearly(year) {
      let i;
      this.loadingAnnual = true;
      this.$_getAnnualEarnings(year, this.userDetails.userId)
        .then((response) => {
          this.totalGrossPay = this.convertToCurrency(
            response.data.employees.annualEarning.grossPay
          );
          this.annualPayPeriod = `01/01/${year} - 31/12/${year}`;
          const netPay =
            Math.round(
              response.data.employees.annualEarning.grossPayPercentage * 100
            ) / 100;
          const deductions =
            Math.round(
              response.data.employees.annualEarning.deductionsPercentage * 100
            ) / 100;
          const pension =
            Math.round(
              response.data.employees.annualEarning.pensionPercentage * 100
            ) / 100;
          const paye =
            Math.round(
              response.data.employees.annualEarning.payEPercentage * 100
            ) / 100;
          this.annualChartData = {
            labels: [
              "Total Net Pay Amount",
              "Total Deductions",
              "Total Pension",
              "Total PAYE",
            ],
            datasets: [
              {
                data: [netPay, deductions, pension, paye],
                backgroundColor: ["#F15A29", "#490166", "#2176FF", "#13B56A"],
              },
            ],
          };
          for (i = 0; i < this.annualChartData.labels.length; i++) {
            const objData = {
              color: this.annualChartData.datasets[0].backgroundColor[i],
              name: this.annualChartData.labels[i],
              value: `${this.annualChartData.datasets[0].data[i]}`,
            };
            this.annualLegend.push(objData);
          }
          this.loadingAnnual = false;
        })
        .catch(() => {
          this.loadingAnnual = false;
        });
    },

    async viewPaySlip(payslipId) {
      this.$router.push({
        name: "viewPaySlip",
        params: { id: payslipId },
      });
    },

    getLengthService() {
      const today = new Date();

      if (this.userDetails.employmentDate) {
        const targetDate = new Date(this.userDetails.employmentDate);

        const differenceInMs = today - targetDate;

        const differenceInDays = differenceInMs / (1000 * 60 * 60 * 24);

        const years = Math.floor(differenceInDays / 365);
        const months = Math.floor((differenceInDays % 365) / 30);
        const days = Math.floor((differenceInDays % 365) % 30);

        if (years) {
          return `${years} Yr(s)`;
        }
        if (months) {
          return `${months} Months`;
        }
        if (days) {
          return `${days} days`;
        }
        return "No length of service";
      }
      return "---";
    },

    getDateRange(value) {
      const splitDate = value.toString().split(",");
      this.start = this.formatDate(splitDate[0]);
      this.end = this.formatDate(splitDate[1]);
      this.selectedDate = value;
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");

      const dateObj = { year: `${year}`, month: `${month}` };
      return dateObj;
    },

    filterPaySlip() {
      this.loadingTable = true;
      let params;

      if (this.paySlipFilter === "range") {
        params = `fromYear=${this.start.year}&fromMonth=${this.start.month}&toYear=${this.end.year}&toMonth=${this.end.month}`;
      } else {
        params = `fromYear=${this.start.year}&fromMonth=${this.start.month}&toYear=${this.start.year}&toMonth=${this.start.month}`;
      }

      this.$_filterPaySlips(this.userDetails.userId, params).then(
        (response) => {
          this.paySlipData = response.data.payslips;
          this.loadingTable = false;
        }
      );
    },
  },

  mounted() {
    let i;

    const employmentTime = new Date(this.userDetails.employmentDate);
    employmentTime.setHours(employmentTime.getHours() + 8);

    const employmentYear = employmentTime.getFullYear();
    const employmentMonth = employmentTime.getMonth() + 1;

    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() + 8);

    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    for (i = employmentYear; i <= currentYear; i++) {
      const setObjYear = { id: i, name: i };
      this.optionYears.push(setObjYear);
    }

    this.selectedYear = currentYear;

    const params = `fromYear=${employmentYear}&fromMonth=${employmentMonth}&toYear=${currentYear}&toMonth=${currentMonth}`;

    this.$_filterPaySlips(this.userDetails.userId, params).then((response) => {
      this.paySlipData = response.data.payslips;
      this.loadingTable = false;
    });

    this.$_getPayrunMonths("?payType=regular").then((result) => {
      const regular = result.data.duration;
      this.payDate = `${regular[0].month}-${regular[0].year}`;
      this.$_getPayrunMonths("?payType=backpay").then((response) => {
        const joinedArr = [...response.data.duration, ...regular];
        joinedArr.forEach((data) => {
          this.optionMonth.push({
            id: `${data.month}-${data.year}`,
            name: data.name,
          });
        });
      });
    });
  },
};
</script>
