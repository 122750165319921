<template>
  <div class="w-10/12">
    <div style="height: 100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else class="mt-6">
      <div class="w-full">
        <p class="font-bold mb-7 text-lg inline-block">Employee Role</p>
        <span
          v-if="!cannotEdit"
          class="float-right cursor-pointer text-flame text-base font-semibold"
        >
          <span @click="handleEdit" v-if="!edit">
            <icon icon-name="icon-edit" size="xs" class="inline-block" />
            <span class="pl-2">Edit</span>
          </span>
          <span @click="handleSave" v-else>
            <icon icon-name="save" size="xs" class="inline-block" />
            <span class="pl-2">Save</span>
          </span>
        </span>
      </div>
      <div
        class="grid grid-cols-12 gap-6 text-darkPurple"
        style="margin-top: 1.125rem"
      >
        <div class="col-span-5">
          <CSelect
            placeholder="--Select Role--"
            :options="roleSelect"
            variant="w-full"
            :disabled="!edit"
            label="Role Name"
            v-model="roleId"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import Loader from "@/components/Loader";

export default {
  name: "EmployeeRole",
  components: {
    CSelect,
    Loader,
  },

  data() {
    return {
      edit: false,
      loading: true,
      roleId: "",
      roleSelect: [],
      success: false,
      response: "",
      responseBg: "",
    };
  },
  props: {
    cannotEdit: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async handleEdit() {
      try {
        await this.$handlePrivilege("employeeDirectory", "editEmployeeRole");
        this.edit = !this.edit;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    getUserRole() {
      const userId = this.$route.params.id;
      this.$_getUser(userId).then((result) => {
        this.loading = false;
        this.roleId =
          Object.keys(result.data.user.roles).length > 0
            ? result.data.user.roles.humanar.roleId
            : "null";
      });
    },
    getHumanarRoles() {
      this.$_NewGetOrgRoles().then((result) => {
        const orgRoles = result.data.roles;
        orgRoles.push({ id: "null", name: "None" });
        this.roleSelect = orgRoles.map((v) => ({ id: v.id, name: v.name }));
      });
    },
    handleSave() {
      const payload = {
        role: {
          appSlug: "humanar",
          roleId: this.roleId === "null" ? null : this.roleId,
        },
      };
      const userId = this.$route.params.id;
      this.$_updateUser({ payload, userId })
        .then(() => {
          this.$toasted.success("New Role Assigned", { duration: 5000 });
          this.edit = false;
        })
        .catch((err) => {
          this.$toasted.error("Role not Assigned", { duration: 5000 });
          throw new Error(err);
        });
    },
  },
  mounted() {
    this.getUserRole();
    this.getHumanarRoles();
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
