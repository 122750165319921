<template>
  <table aria-label="org data structure">
    <tbody>
      <tr>
        <td
          :colspan="
            datasource.children && datasource.children.length
              ? datasource.children.length * 2
              : null
          "
        >
          <div
            class="node"
            style="
              background: #f7f7ff;
              box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
              border-radius: 3px;
              padding: 10px 10px 20px 10px;
              max-width: 400px;
            "
            :id="datasource.id"
            @click.stop="handleClick(datasource)"
          >
            <slot :node-data="datasource">
              <div class="w-full flex">
                <img
                  class="tw-mx-auto tw-rounded-full"
                  style="height: 30px; width: 29.27px"
                  :src="datasource.users[0].img || accounts"
                  alt="account"
                  v-if="datasource.users[0].img"
                />
                <div
                  v-else
                  class="w-8 h-8 rounded text-blueCrayola border text-center font-semibold pt-2"
                >
                  {{
                    $getInitials(
                      `${datasource.users[0].fname} ${datasource.users[0].lname}`
                    )
                  }}
                </div>
                <div class="tw-font-bold" style="font-size: 8px">
                  {{ datasource.name }}
                </div>
                <div class="tw-pb-2" style="font-size: 6px; color: #8e8782">
                  {{ datasource.name }}
                </div>
              </div>
            </slot>
          </div>
        </td>
      </tr>
      <template v-if="datasource.children && datasource.children.length">
        <tr class="lines">
          <td :colspan="datasource.children.length * 2">
            <div class="downLine"></div>
          </td>
        </tr>
        <tr class="lines">
          <td class="rightLine"></td>
          <template v-for="n in datasource.children.length - 1">
            <td :key="n" class="leftLine topLine"></td>
            <td :key="n + 1000" class="rightLine topLine"></td>
          </template>
          <td class="leftLine"></td>
        </tr>
        <tr class="nodes">
          <td colspan="2" v-for="child in datasource.children" :key="child.id">
            <node :datasource="child" :handle-click="handleClick">
              <template
                v-for="slot in Object.keys($scopedSlots)"
                :slot="slot"
                slot-scope="scope"
              >
                <slot :name="slot" v-bind="scope" />
              </template>
            </node>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import accounts from "@/assets/icons/source/account.svg";

export default {
  name: "Node",
  data() {
    return {
      accounts,
    };
  },
  props: {
    datasource: {
      type: Object,
      default: () => {}
    },
    handleClick: {
      type: Function,
      default: () => {}
    },
    image: Boolean,
  },
  methods: {},
};
</script>

<style>
tbody tr:nth-child(even) {
  background-color: #ffffff;
}
</style>