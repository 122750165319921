<template>
  <div class="w-full flex flex-col gap-3">
    <div v-if="!showError">
      <p v-if="rolesData.length > 0" class="text-base font-semibold">Roles</p>
      <p v-if="rolesData.length > 0" class="text-sm text-romanSilver">
        {{ userDetails.fname }} {{ userDetails.lname }} is currently profiled
        for {{ rolesData.length }} role(s)
      </p>

      <div style="height: 100%" class="mt-28 pt-10" v-if="loading">
        <loader size="xxs" :loader-image="false" />
      </div>

      <div v-else>
        <div class="flex flex-col w-full my-5" v-if="rolesData.length > 0">
          <sTable
            :headers="rolesHeaders"
            :items="rolesData"
            style="width: 100%"
            aria-label="employee table"
            class="w-full"
            has-number
          >
            <template v-slot:item="{ item }">
              <div v-if="item.roleId" class="py-2">
                {{ item.data.roleId ? item.data.roleId : "---" }}
              </div>
              <div
                v-else-if="item.roleName"
                class="py-2 w-full flex flex-col gap-1"
              >
                <p class="text-sm">
                  {{ item.data.roleName ? item.data.roleName : "---" }}
                </p>
                <p class="text-sm text-romanSilver">
                  {{
                    item.data.users
                      ? `${item.data.users} other employees are assigned to this role`
                      : ""
                  }}
                </p>
              </div>
              <div v-else-if="item.id" class="flex">
                <div class="flex gap-8">
                  <Menu
                    left
                    top="-300"
                    margin="24"
                    class="p-0"
                    @click.native="viewRoles(item.data.id)"
                  >
                    <template v-slot:title>
                      <Icon
                        icon-name="icon-eye"
                        class-name="text-romanSilver"
                        size="xs"
                      />
                    </template>
                    <div
                      class="flex flex-col justify-start items-center flex-grow gap-3 px-3 py-2"
                      style="min-width: 250px"
                    >
                      <div class="flex flex-col gap-1 my-1 py-1 w-full">
                        <p class="text-sm font-semibold">
                          {{ item.data.roleName }}
                        </p>
                        <p class="text-sm text-romanSilver">
                          Role ID: {{ item.data.roleId }}
                        </p>
                      </div>
                      <div class="w-full border-2 -mt-1 border-dashed" />
                      <div
                        style="height: 100%"
                        class="mt-4"
                        v-if="loadingUsers"
                      >
                        <loader size="xxs" :loader-image="false" />
                      </div>
                      <div class="flex flex-col gap-4 w-full my-3" v-else>
                        <div
                          class="flex gap-3 w-full justify-start"
                          v-for="(roleUser, index) in roleUsers"
                          :key="index"
                        >
                          <div>
                            <img
                              v-if="roleUser.photo"
                              :src="roleUser.photo"
                              alt="profile pic"
                              class="w-8 h-8 rounded"
                            />
                            <div
                              v-else
                              class="w-8 h-8 rounded text-blueCrayola border text-center font-semibold pt-2"
                            >
                              {{
                                $getInitials(
                                  `${roleUser.fname} ${roleUser.lname}`
                                )
                              }}
                            </div>
                          </div>
                          <div class="flex flex-col justify-center">
                            <p class="text-sm font-semibold">
                              {{ roleUser.fname }} {{ roleUser.lname }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Menu>
                  <span
                    class="cursor-pointer"
                    @click="setUpModal(item.data.id)"
                  >
                    <Icon
                      icon-name="icon-trash"
                      size="xs"
                      class-name="text-desire"
                    />
                  </span>
                </div>
              </div>
            </template>
          </sTable>
        </div>

        <div class="flex flex-col gap-1" v-else>
          <div class="flex w-full justify-center">
            <Icon
              icon-name="dashboard-error"
              class="-mt-1 iconSize"
              style="width: 250px; height: 250px"
            />
          </div>
          <p class="text-xl text-center font-bold">No Data Available</p>
          <p class="text-xs text-center text-romanSilver">
            {{ userDetails.fname }} {{ userDetails.lname }} is currently profiled
        for {{ rolesData.length }} role(s)         </p>
        </div>
      </div>

      <Modal v-if="roleModal">
        <Card class="p-5 flex flex-col max-w-md">
          <div class>
            <p class="font-bold ml-4 my-5 text-center">
              Are you sure you want to delete Employee from this role?
            </p>
            <div class="flex w-full">
              <Button
                class="text-white bg-flame rounded-md cursor-pointer p-3 w-full"
                @click="deleteRole(roleId)"
              >
                Yes
              </Button>
              <Button
                background-color="none"
                class="text-dynamicBackBtn border border-solid p-3 w-full rounded-md cursor-pointer border-dynamicBackBtn ml-4"
                @click="roleModal = false"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Card>
      </Modal>
    </div>
    <div v-else>
      <ErrorPage />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Menu from "@scelloo/cloudenly-ui/src/components/Menu";
import Modal from "@/components/Modal";
import Card from "@/components/Card";
import ErrorPage from "@/modules/Admin/error403";

export default {
  name: "AdminRoles",
  components: { STable, Menu, Card, Modal, ErrorPage },
  props: {
    userDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      rolesHeaders: [
        { title: "Role Id", value: "roleId", width: 25 },
        { title: "Role Name", value: "roleName", width: 65 },
        { title: "", value: "id", image: true, width: 10 },
      ],
      rolesData: [],
      loading: true,
      loadingUsers: true,
      roleUsers: [],
      roleId: "",
      roleModal: false,
      showError: false,
    };
  },
  computed: {
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },
  methods: {
    async setUpModal(id) {
      try {
        await this.$handlePrivilege("employeeDirectory", "deleteAdminRoleED");
        this.roleId = id;
        this.roleModal = true;
      } catch (error) {
        const message = "You do not have permission to perform this task";
        this.$toasted.error(message, { duration: 3000 });
      }
    },
    fetchEmployeeRoles() {
      this.rolesData = [];
      this.$_getEmployeeInsightRoles(this.userDetails.userId)
        .then((response) => {
          this.loading = false;
          const data = response.data.data.roles;
          this.rolesData = data.map((roleData) => ({
            ...roleData,
            id: roleData.role.id,
            roleName: `${roleData.role.name}(${roleData.role.description})`,
            users: roleData.others > 1 ? roleData.others : null,
          }));
        })
        .catch(() => {
          this.loading = false;
        });
    },
    viewRoles(id) {
      this.$_getUsersInsightRoles(id, this.userDetails.userId).then(
        (response) => {
          this.roleUsers = response.data.data.users;
          this.loadingUsers = false;
        }
      );
    },
    deleteRole(id) {
      this.roleModal = false;
      this.$_deleteRole(this.userDetails.userId, id)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.loading = true;
          this.fetchEmployeeRoles();
        })
        .catch((error) => {
          this.$toasted.error(error, {
            duration: 5000,
          });
        });
    },
  },
  async mounted() {
    try {
      await this.$handlePrivilege("employeeDirectory", "viewAdminRoleED");
      this.fetchEmployeeRoles();
    } catch (error) {
      this.showError = true;
    }
  },
};
</script>
