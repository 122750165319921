<template>
  <div class="w-full flex flex-col gap-3">
    <div v-if="!showError">
    <p class="text-base font-semibold">Approvals</p>

    <div style="height: 100%" class="mt-28 pt-10" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>

    <div v-else>
      <div class="flex flex-col w-full my-3" v-if="approvalsData.length > 0">
        <sTable
          :headers="approvalHeader"
          :items="approvalsData"
          style="width: 100%"
          aria-label="employee table"
          class="w-full"
          has-number
        >
          <template v-slot:item="{ item }">
            <div v-if="item.flowId" class="p-2">
              {{ item.data.flowId ? item.data.flowId : "---" }}
            </div>
            <div v-else-if="item.workFlowName" class="p-2">
              {{ item.data.workFlowName ? item.data.workFlowName : "---" }}
            </div>
            <div v-else-if="item.initiatorType" class="p-2 capitalize">
              {{ item.data.initiatorType ? item.data.initiatorType : "---" }}
            </div>
            <div v-else-if="item.approversCount" class="p-2 capitalize">
              {{ item.data.approversCount ? item.data.approversCount : "---" }}
            </div>
            <div v-else-if="item.groupId">
              <div
                class="w-full flex justify-start items-center gap-2 cursor-pointer"
                @click="editRole(item.data.groupId)"
              >
                <Icon
                  icon-name="edit"
                  class-name="text-blueCrayola"
                  size="xs"
                />
              </div>
            </div>
          </template>
        </sTable>
      </div>

      <div class="flex flex-col gap-1" v-else>
        <div class="flex w-full justify-center">
          <Icon
            icon-name="dashboard-error"
            class="-mt-1 iconSize"
            style="width: 250px; height: 250px"
          />
        </div>
        <p class="text-xl text-center font-bold">No Data Available</p>
        <p class="text-xs text-center text-romanSilver">
          There is no data to show currently.
        </p>
      </div>
    </div>
    </div>
    <div v-else>
      <ErrorPage />
    </div>
  </div>
</template>

<script>
import STable from "@scelloo/cloudenly-ui/src/components/table";
import ErrorPage from "@/modules/Admin/error403";

export default {
  name: "ApprovalRoles",
  components: { STable, ErrorPage },
  data() {
    return {
      loading: true,
      approvalHeader: [
        { title: "Workflow Id", value: "flowId" },
        { title: "Workflow Name", value: "workFlowName" },
        { title: "Scope", value: "initiatorType" },
        { title: "Sequence", value: "approversCount" },
        { title: "", value: "groupId", image: true },
      ],
      approvalsData: [],
      showError: false,
    };
  },
  props: {
    userDetails: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    async editRole(groupId) {
      try {
        await this.$handlePrivilege("employeeDirectory", "editApprovalRoleED");
        this.$router.push({
          name: 'ViewWorkflow',
          params: { workflowId: groupId },
        });
      } catch (error) {
        const message = "You do not have permission to perform this task";
        this.$toasted.error(message, { duration: 3000 });
      }
    }
  },
  async mounted() {
    try {
      await this.$handlePrivilege("employeeDirectory", "viewApprovalRoleED");
      this.$_getEmployeeApprovalInsights(this.userDetails.userId).then(
        (response) => {
          this.approvalsData = response.data.data.empApproval;
          this.loading = false;
        }
      );
    } catch (error) {
      this.showError = true;
    }
  },
};
</script>
