<template>
  <div>
    <div class=" w-full">
      <p class=" font-semibold mb-4 inline-block">
        Other Benefits
      </p>
      <span
        v-if="!cannotEdit"
        class="float-right cursor-pointer text-flame text-base font-semibold"
      >
        <span v-if="!edit" @click="handleClick">
          <icon icon-name="icon-edit" size="xs" class=" inline-block" />
          <span class="pl-2">Edit</span>
        </span>
        <span v-else @click="saveBenefits">
          <icon icon-name="save" size="xs" class=" inline-block" />
          <span class="pl-2">Save</span>
        </span>
      </span>
    </div>
    <table aria-label="other benefits table" class=" w-full">
      <thead>
        <tr>
          <th id="0">#</th>
          <th
            class=" uppercase"
            v-for="(header, index) in headers"
            :key="index"
            :id="index"
          >
            <p>{{ header.title }}</p>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(data, i) in otherBenefitData"
          :key="i"
          class="tableBody text-sm"
          style="height: 48px; "
        >
          <td>
            {{ i + 1 }}
          </td>
          <td>
            {{ data.payItem.name }}
          </td>
          <td>
            {{ data.nameOnPayslip }}
          </td>
          <td>
            {{ Capitalize(data.payType) }}
          </td>
          <td>
            {{ data.rateType.label }}
          </td>
          <td>
            <span v-if="!edit">
              {{
                data.rateType.slug === "fixedAmount" ||
                data.rateType.placeholder === "Enter Amount" ||
                data.rateType.slug === "dailyRate"
                  ? "₦" + data.rateValue
                  : data.rateType.dataType === "percent"
                  ? data.rateValue + "%"
                  : data.rateType.placeholder === "Enter Multiple (eg x 1.5)"
                  ? data.rateValue + "X"
                  : ""
              }}
            </span>
            <span v-else>
              <div class=" w-24 flex">
                <c-text v-model="data.rateValue" />
                <span class="my-auto pl-2">{{
                  data.rateType.slug === "fixedAmount"
                    ? "₦"
                    : data.rateType.dataType === "percent"
                    ? "%"
                    : data.rateType.placeholder === "Enter Multiple (eg x 1.5)"
                    ? "X"
                    : ""
                }}</span>
              </div>
            </span>
          </td>
          <td>
            {{ convertToCurrency(data.actualAmount) }}
          </td>
        </tr>
        <tr class="h-7" />
      </tbody>
    </table>
  </div>
</template>

<script>
import CText from "@scelloo/cloudenly-ui/src/components/text";
import { mapState } from "vuex";

export default {
  components: { CText },
  props: {
    totalGross: {
      type: String,
      default: ""
    },
    otherBenefitData: {
      type: Array,
      default() {
        return [];
      }
    },
    totalGrossEarning: {
      type: Number,
      default: 0
    },
    cannotEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      edit: false,
      headers: [
        { title: "pay item" },
        { title: "name in payslip" },
        { title: "pay type" },
        { title: "rate type" },
        { title: "rate" },
        { title: "rate amount(₦)" }
      ]
    };
  },
  computed: {
    ...mapState({
      employeeDetails: state => state.employee.allEmployeeDetails
    }),
    totalAmount() {
      let result = 0;
      let number = 0;
      this.otherBenefitData.forEach(element => {
        number = parseFloat(element.amount.split(",").join(""));
        result += number;
      });
      result = result.toLocaleString();
      return result;
    }
  },
  methods: {
    async handleClick() {
      try {
        await this.$handlePrivilege("employeeDirectory", "editEmployeePayInfo");
        this.edit = !this.edit;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000
        });
      }
    },
    saveBenefits() {
      const paySetting = { paysettings: [] };
      paySetting.employeeId = this.employeeDetails.id;
      this.otherBenefitData.forEach(element => {
        paySetting.paysettings.push({
          paySettingId: element.paySettingId,
          employeeId: this.employeeDetails.id,
          rateValue: element.rateValue
        });
      });
      this.$_saveEmployeepayItem(paySetting).then(() => {
        this.$emit("submit");
        this.edit = !this.edit;
      });
    }
  }
};
</script>

<style></style>
