<template>
  <div>
    <div v-if="!showError">
      <div style="height: 100%" v-if="loading">
        <loader size="xxs" :loader-image="false" />
      </div>

      <org-chart
        class="-mt-8"
        v-else
        :datasource="ds"
        style="transform: rotate(180deg)"
      >
        <template slot-scope="{ nodeData }">
          <div class="w-full flex gap-3 org-node">
            <div class="flex">
              <img
                class="tw-mx-auto tw-rounded-full"
                style="height: 30px; width: 30px"
                :src="nodeData.photo"
                alt="account"
                v-if="nodeData.photo"
              />
              <div
                v-else
                class="w-8 h-8 rounded text-blueCrayola border text-center font-semibold pt-1"
              >
                {{ $getInitials(`${nodeData.name}`) }}
              </div>
            </div>
            <div class="flex flex-col gap-1">
              <div class="font-semibold flex text-base text-romanSilver">
                {{ nodeData.name }}
              </div>
              <div
                class="text-xs text-darkPurple capitalize text-left flex flex-col gap-1"
              >
                <p class="font-semibold">
                  {{ nodeData.title }}
                </p>
              </div>
            </div>
          </div>
          <div
            class="w-full flex justify-center mb-3"
            v-if="nodeData.users.length > 1"
          >
            <div
              class="w-[48px] py-2 px-3 -bottom-2 bg-darkPurple rounded-md absolute"
            >
              <p class="text-white text-xs font-semibold">
                +{{ nodeData.users.length }}
              </p>
            </div>
          </div>
        </template>
      </org-chart>
    </div>
    <div v-else>
      <ErrorPage />
    </div>
  </div>
</template>

<script>
import ErrorPage from "@/modules/Admin/error403";
import OrgChart from "./OrganizationChartContainer";

export default {
  name: "ReportingLine",
  components: { OrgChart, ErrorPage },
  data() {
    return {
      ds: [],
      loading: true,
      showError: false,
    };
  },
  props: {
    userDetails: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    transformData(node) {
      if (!node) return null;
      const transformedNode = {
        id: node.id,
        name: `${node.fname} ${node.lname}`,
        title: `Level ${node.level.name}`,
        photo: node.photo || null,
        users: node.otherDesignationUser
      };
      if (node.reportLine) {
        transformedNode.children = [this.transformData(node.reportLine)];
      }
      return transformedNode;
    },
  },
  async mounted() {
    try {
      await this.$handlePrivilege("employeeDirectory", "viewReportingLineED");
      this.$_getEmployeeReportingLineInsights(this.userDetails.userId).then(
        (response) => {
          this.ds = this.transformData(response.data.data);
          this.loading = false;
        }
      );
    } catch (error) {
      this.showError = true;
      this.loading = false;
    }
  },
};
</script>

<style>
.org-node::after {
  content: '';
  position: absolute;
  top: -104px; /* Adjust as needed */
  left: 50.2%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #ccc; /* Same as border color of .org-node */
}
</style>